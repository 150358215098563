import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import handleHospital from "@/services/modules/hospital";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const pdfPrinter = () => { 

    const { dateOfBarthConvertToAge } = handleHospital();

    const exportToPDF = (company, investigation, barcode, qrcode, barcodePatient, userName, btnTitle, isHideHeader = false, isHideFooter = false) => {

        var doc = {
            pageSize: 'A4',
            pageMargins: [ 60, 100, 60, 80 ],
            header: !isHideHeader ? getHeader(company) : {},
            footer: !isHideFooter ? getFooter(userName, qrcode) : {},
            content: getContent(investigation, barcode, barcodePatient, userName, btnTitle),

            styles : {
                header: {
                    fontSize: 24,
                },

                tableHeader: {
                    fontSize: 16,
                }
            },

            defaultStyle: {
                color: 'black',
                fontSize: 10
            },

            info: {
                title: investigation.id
            }
        }

        pushIntoTable(doc, investigation.formattedGenerals, investigation)

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const calculateAge = (birthday) => {
        const {year, month, day} = dateOfBarthConvertToAge(birthday)
        return `${year}Y, ${month}M, ${day}D`
    }

    const formatDate = (date) => {
        var formattedDate = date.toISOString().split('T')[0];
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var amOrPm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;
        return formatToDdMmYy(formattedDate) + ' ' + formattedTime
    }

    const formatToDdMmYy = (date) => {
        var dat = date.split('-');
        return `${dat[2]}-${dat[1]}-${dat[0]}`;
    }

    const getHeader = (company) => {
        return {
            margin: [ 60, 25, 60, 10 ],
            columns: [
                {
                    alignment: 'left',
                    image: company.logo64,
                    maxHeight: 60,
                    maxWidth: 60
                },

                {
                    alignment: 'right',
                    stack: [
                        company.name,
                        'Address: ' + company.address,
                        'Phone: ' + company.phone,
                        'E-mail: ' + company.email
                    ]
                }
            ]
        }
    }

    const getContent = (investigation, barcode, barcodePatient, userName, btnTitle) => {

        return  [
            {
                text: btnTitle,
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
            },

            {
                margin: [0, 20, 0, 0],
                alignment: 'justify',
                columns: [
                    {
                        width: '50%',
                        stack: [
                            {
                                svg: barcodePatient
                            },

                            {
                                text: [
                                    {
                                        text: 'Patient ID: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },
                                    {
                                        text: investigation.contact_profile.id
                                    }
                                ]
                            },

                            {
                                text: [
                                    {
                                        text: 'Patient Name: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },
                                    {
                                        text: investigation.contact_profile.full_name
                                    }
                                ]
                            },

                            {
                                text: [
                                    {
                                        text: 'Age: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },
                                    {
                                        text: `${(investigation.contact_profile && investigation.contact_profile.birthday) ? 
                                            calculateAge(investigation.contact_profile.birthday) : ''}` + 
                                            `${(investigation.contact_profile && investigation.contact_profile.gender) ? 
                                            ' | Gender: ' + investigation.contact_profile.gender : '' }`
                                    }
                                ]
                            },

                            {
                                text: [
                                    {
                                        text: 'Mobile no: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },
                                    {
                                        text: investigation.contact_profile.mobile_no
                                    }
                                ]
                            },

                            {
                                text: [
                                    {
                                        text: 'Ref By: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },
                                    {
                                        text: investigation.service_resource.name
                                    }
                                ]
                            }
                            
                        ]
                    },

                    {
                        alignment: 'right',
                        stack: [
                            {
                                svg: barcode
                            },

                            {
                                text: [
                                    {
                                        text: 'INVOICE NO: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },
                                    {
                                        text: investigation.bill_number
                                    }
                                ]
                            },

                            {
                                text: [
                                    {
                                        text: 'INVOICE DATE: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },

                                    {
                                        text: formatToDdMmYy(investigation.date),
                                    }
                                 ]
                            },
                            
                            {
                                text: [
                                    {
                                        text: 'SL No: ',
                                        bold: true,
                                        margin: [0, 20, 10, 0]
                                    },

                                    {
                                        text: ''
                                    }
                                 ]
                            }
                        ]
                    }
                ]
            }
        ]
    }

    const getFooter = (userName, qrcode) =>{
        return {
            margin: [ 60, 0, 60, 0 ],
            columns: [
                {
                    columns: [
                        {
                            svg: qrcode
                        },

                        {
                            width: '90%',
                            alignment: 'left',
                            text: 'N.B: This is a system generated documents and requires no manual signature.',
                            margin: [5, 53, 0, 0],
                            fontSize: 8
                        }
                    ]
                },

                {
                    width: '20%',
                    alignment: 'right',
                    fontSize: 8,
                    margin: [0, 33, 0, 0],
                    stack: [
                        {
                            text: 'Printed by',
                            bold: true
                        },

                        {
                            text: userName
                        },

                        {
                            text: formatDate(new Date())
                        }
                    ]
                }
            ]
        }
    }

    const getFooterTop = (investigation) => {
        return {
            alignment: 'justify',
            absolutePosition: { x: 63, y: 670 },
            columns: [
                {
                    width: '50%',
                    stack: [
                        {
                            text: investigation.prepared_by ? investigation.prepared_by.short_description : '',
                            alignment: 'left',
                            width: '50%'
                        }
                    ]
                },
                {
                    width: '50%',
                    alignment: 'right',
                    stack: [
                        {
                            text: investigation.approved_by ? investigation.approved_by.short_description : '',
                            alignment: 'right',
                            width: '50%'
                        }
                    ]
                },
            ]
        }
    }

    const pushIntoTable = (doc, reports, investigation) => {

        const tableHeader = getTableHeader()
        
        const content = {
            style: 'tableExample',
            margin: [0, 20, 0, 0],
            table: {
                widths: ['*', '20%', '10%', '30%'],
                body: []
            },
            layout: {
                fillColor: function (rowIndex, node, columnIndex) {
                  return '';
                },
                hLineWidth: function (i, node) {
                  return 0.5;
                },
                vLineWidth: function (i, node) {
                  return 0;
                },
                hLineColor: function (i, node) {
                  return ''; 
                },
                vLineColor: function (i, node) {
                  return (i === 0 || i === node.table.widths.length) ? '#000000' : '#dddddd';
                },
            }
        }

        content.table.body.push(tableHeader);
        
        for(const key in reports){
            const tableTitle = getRowTitle(key);
            content.table.body.push(tableTitle);
            setTableContent(reports[key], content);
        }

        doc.content.push(content);
        
        doc.content.push(getFooterTop(investigation));
    }

    const setTableContent = (reports, content) => {
        for(const report of reports){
            content.table.body.push(getRowData(report))
        }
    }

    const getTableHeader = () => {
        return [
            { text: 'Parameter', bold: true },
            { text: 'Result', bold: true },
            { text: 'Unit', bold: true },
            { text: 'Reference value', bold: true }
        ];
    }
    
    const getRowTitle = (title) => {
        return [ 
            { text: title, bold: true, colSpan: 4, margin: [0, 0, 0, 0]},
            {},
            {},
            {},
        ];
    }
    
    const getRowData = (report) => {
        return [ 
            { text: report.name, margin: [15, 0, 0, 0]},
            { text: report.result },
            { text: report.unit },
            { text: report.reference_value } 
        ];
    }

    return {
        exportToPDF
    }
}

export default pdfPrinter;
